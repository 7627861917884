// App.js

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
// Import the pirate-esque icon from Font Awesome (e.g. skull-crossbones)
import { faSkullCrossbones } from '@fortawesome/free-solid-svg-icons';

import './App.css';

function App() {
  // Initialize Google Analytics 4
  const GA_MEASUREMENT_ID = 'G-RWBCFERESJ'; // Replace with your GA4 Measurement ID
  ReactGA.initialize(GA_MEASUREMENT_ID);

  useEffect(() => {
    // Send a pageview when the app loads
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });

    // Load the LinkedIn script after the component mounts
    const script = document.createElement('script');
    script.src = 'https://platform.linkedin.com/badges/js/profile.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  // Function to handle link clicks and send events to GA4
  const handleLinkClick = (label) => {
    ReactGA.event({
      category: 'Link',
      action: 'Click',
      label: label,
    });
  };

  // Function to handle navigation link clicks
  const handleNavClick = (label) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: label,
    });
  };

  return (
    <div className="App">
      {/* Navigation */}
      <nav>
        <ul>
          <li>
            <a href="#home" onClick={() => handleNavClick('Home')}>
              Home
            </a>
          </li>
          <li>
            <a href="#projects" onClick={() => handleNavClick('Projects')}>
              Projects
            </a>
          </li>
          <li>
            <a href="#about-contact" onClick={() => handleNavClick('About & Contact')}>
              About & Contact
            </a>
          </li>
        </ul>
      </nav>

      {/* Home Section */}
      <section id="home" className="section home-section">
        <h1>Selim Harfouche</h1>
        <p>Software Engineer and Cybersecurity Enthusiast</p>
        <p>
          Welcome to my personal website where I showcase my projects and reports.
          Feel free to explore my work below!
        </p>
      </section>

      {/* Projects Section */}
      <section id="projects" className="section projects-section">
        <h2>Projects</h2>
        <ul>
          <li>
            <h3>Music ZKP System</h3>
            <p>
              Developed a decentralized application using zero-knowledge proofs that allows musicians to register melody ownership on blockchain without revealing the actual notes.
            </p>
            <a
              href="https://harfouche-724.vercel.app/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Music ZKP System')}
            >
              View Project
            </a>
          </li>
          <li>
            <h3>Wellplates Search Engine with 2D/3D Viewing</h3>
            <p>
              Developed a dynamic search engine for wellplates featuring real-time updates and integrated 2D and 3D visualization tools.
            </p>
            <a
              href="https://wellplates.selimharfouche.com/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Wellplates Search Engine')}
            >
              View Project
            </a>
          </li>
          <li>
            <h3>Automatic Guitar Tuner</h3>
            <p>
              Created an automated guitar tuner that uses frequency detection to adjust strings to accurate pitches.
            </p>
            <a
              href="https://drive.google.com/drive/folders/17GV4VK-PX0VZ1e7AveD9fNhyqcSe16tT?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Automatic Guitar Tuner')}
            >
              View Project
            </a>
          </li>
          <li>
            <h3>OCR Learner</h3>
            <p>
              Implemented an adaptive OCR system that incrementally improves accuracy through continuous learning.
            </p>
            <a
              href="https://github.com/selimharfouche/COE544_F2022"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('OCR Learner')}
            >
              View Project
            </a>
          </li>
          <li>
            <h3>NFT Marketplace on Mumbai Network</h3>
            <p>
              Developed an NFT marketplace on the Polygon (Mumbai) network, enabling smooth digital asset transactions.
            </p>
            <a
              href="https://drive.google.com/drive/folders/1ISR6xknEQPmUsU1LxFp_Vd5s3w0fGG4I?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('NFT Marketplace')}
            >
              View Project
            </a>
          </li>
          <li>
            <h3>Farm DBMS</h3>
            <p>
              Designed a comprehensive database management system to streamline farm operations and enhance efficiency.
            </p>
            <a
              href="https://drive.google.com/drive/folders/1Yo-6qw2tFrRQ-BrtND7WMJDc-paRqR63?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Farm DBMS')}
            >
              View Project
            </a>
          </li>
          <li>
            <h3>DES Encryption System</h3>
            <p>
              Implemented a Data Encryption Standard (DES) system incorporating randomization techniques for improved security.
            </p>
            <a
              href="https://drive.google.com/drive/folders/1OFtzVL7LaTW69JAL6KmCMUGKNscs3xQB?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('DES Encryption System')}
            >
              View Project
            </a>
          </li>
        </ul>
      </section>

      {/* About & Contact Section */}
      <section id="about-contact" className="section about-contact-section">
        {/* About Me Content */}
        <h2>About Me</h2>
        <p>
          I'm currently pursuing an Advanced Master's in Cybersecurity and Cyberdefense at the
          Institut Polytechnique de Paris. I also hold a Bachelor's in Computer Engineering with a
          minor in Business from the Lebanese American University. I have experience in software
          engineering, web development, and cybersecurity.
        </p>
        {/*<p>
          Check out my <a href="/SelimHarfoucheCV.pdf" download>resume</a> for more details about my education and professional experience.
        </p>*/}

        {/* LinkedIn Badge */}
        <div className="linkedin-button">
          <div
            className="LI-profile-badge"
            data-version="v1"
            data-size="medium"
            data-locale="en_US"
            data-type="vertical"
            data-theme="light"
            data-vanity="selimharfouche"
          >
            {/* LinkedIn Badge */}
          </div>
        </div>

        {/* Contact Content */}
        <h2>Contact</h2>
        <p>
          If you would like to get in touch, feel free to email me or connect with me on LinkedIn.
        </p>
        {/* Contact Icons */}
        <div className="contact-icons">
          {/* Email */}
          <a
            href="mailto:sharfouche@proton.me"
            onClick={() => handleLinkClick('Email')}
          >
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>

          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/in/selimharfouche"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleLinkClick('LinkedIn')}
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>

          {/* Root-Me Profile (Pirate Icon) */}
          <a
            href="https://www.root-me.org/CryptusAurelius"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleLinkClick('Root Me')}
          >
            <FontAwesomeIcon icon={faSkullCrossbones} size="2x" />
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer>
        <p>&copy; 2025 Selim Harfouche. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
